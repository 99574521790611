import * as React from 'react'
import { TalentKycStatus, TalentStatus } from '@upper/graphql/freelancer'
import {
  HourglassIcon,
  VerificationPersonalInterviewIcon,
  VerificationTechnicalInterviewIcon,
} from '@upper/icons'
import { OnboardingIcon } from '../components/icons'

export const TALENT_STATUS_BANNER_TITLE_NO_ACCESS: Partial<
  Record<TalentStatus, string>
> = {
  [TalentStatus.Applied]: 'You are on the waitlist.',
  [TalentStatus.AppliedToUpper]: 'You are on the waitlist.',
  [TalentStatus.Invited]: 'You are on the waitlist.',
  [TalentStatus.PersonalInterview]: 'Your account is in vetting.',
  [TalentStatus.Verification]: 'Your account is in vetting.',
  [TalentStatus.Challenge]: 'Your account is in vetting.',
  [TalentStatus.TechnicalInterview]: 'Your account is in vetting.',
  [TalentStatus.Onboarding]: 'Your account is in vetting.',
}

export const TALENT_STATUS_BANNER_TITLE_ACCESS: Partial<
  Record<TalentStatus, string>
> = {
  [TalentStatus.Applied]: 'Welcome, {firstName}!',
  [TalentStatus.AppliedToUpper]: 'Welcome, {firstName}!',
  [TalentStatus.Invited]: 'Welcome, {firstName}!',
  [TalentStatus.PersonalInterview]: 'Your account is in vetting.',
  [TalentStatus.Verification]: 'Welcome to the freelancer app!',
  [TalentStatus.Challenge]: 'Your account is in vetting.',
  [TalentStatus.TechnicalInterview]: 'Your account is in vetting.',
  [TalentStatus.Onboarding]: 'Your account is in vetting.',
}

export const TALENT_STATUS_LABELS_NO_ACCESS: Partial<
  Record<TalentStatus, string>
> = {
  [TalentStatus.Applied]: 'Please stand by while we process your application.',
  [TalentStatus.AppliedToUpper]:
    'Please stand by while we process your application.',
  [TalentStatus.Invited]: 'Please stand by while we process your application.',
  [TalentStatus.Waitlist]: 'Waitlist',
  [TalentStatus.PersonalInterview]: 'Personal Interview',
  [TalentStatus.TechnicalInterview]: 'Technical Interview',
  [TalentStatus.Onboarding]: 'UPPER Onboarding',
  [TalentStatus.Challenge]: 'Challenge',
  [TalentStatus.Verification]: 'Identity Check',
}

export const TALENT_STATUS_LABELS_ACCESS: Partial<
  Record<TalentStatus, string>
> = {
  [TalentStatus.Verification]: 'Identify check',
  [TalentStatus.PersonalInterview]: 'Personal Interview',
  [TalentStatus.TechnicalInterview]: 'Technical Interview',
  [TalentStatus.Challenge]: 'Challenge',
  [TalentStatus.Onboarding]: 'UPPER Onboarding',
}

export const TALENT_STATUS_ICONS: Partial<
  Record<TalentStatus, (props: React.ComponentProps<'svg'>) => JSX.Element>
> = {
  [TalentStatus.Applied]: HourglassIcon,
  [TalentStatus.AppliedToUpper]: HourglassIcon,
  [TalentStatus.Invited]: HourglassIcon,
  [TalentStatus.Waitlist]: HourglassIcon,
  [TalentStatus.Verification]: VerificationPersonalInterviewIcon,
  [TalentStatus.PersonalInterview]: VerificationPersonalInterviewIcon,
  [TalentStatus.TechnicalInterview]: VerificationTechnicalInterviewIcon,
  [TalentStatus.Onboarding]: OnboardingIcon,
  [TalentStatus.Challenge]: VerificationTechnicalInterviewIcon,
}

export const TALENT_STATUS_INFOS_NO_ACCESS: Partial<
  Record<Exclude<TalentStatus, 'Verification'>, string> &
    Record<TalentStatus.Verification, Record<TalentKycStatus, string>>
> = {
  [TalentStatus.Waitlist]:
    'Your profile is currently being reviewed by our Talent Team.<br />If you are a good fit for our community, you will be invited to the next interview rounds.',
  [TalentStatus.PersonalInterview]:
    'Please check your email to schedule a call with one of our talent specialists.',
  [TalentStatus.TechnicalInterview]:
    'Please check your email to schedule your technical interview.',
  [TalentStatus.Onboarding]:
    'Congratulations! You have made it past all of our vetting steps - all that`s left now is to sign the framework agreement for your account to be fully verified and active.',
  [TalentStatus.Challenge]:
    'Please check your email to access and solve the challenge.',
  [TalentStatus.Verification]: {
    Declined:
      'Your identity verification failed. Feel free to get in touch if you believe this was in error and would like to try again.',
    NotStarted:
      'Please check your email and verify your identity via the provided link. With a verified ID your will be able to view and apply to jobs.',
    Abandoned:
      'Please check your email and verify your identity via the provided link. With a verified ID your will be able to view and apply to jobs.',
    Approved:
      'The identity check was completed successfully - we will proceed with the full vetting process once there is fitting job. In the meanwhile you can browse and apply to jobs.',
    Expired:
      'Your identify verification attempt has expired. Please start a new session using the button below or contact us if you had any issues.',
    Started:
      'Your identify verification is in progress. Please follow the instructions in the popup or link in order to complete the process.',
    Submitted:
      'Your identify verification is in progress. We are waiting for a conclusion from our partner and will let you know as soon as that happens.',
  },
}

export const TALENT_STATUS_INFOS_ACCESS: Partial<Record<TalentStatus, string>> =
  {
    [TalentStatus.Invited]: `You can now browse all jobs and apply by yourself or get invited. We will finalise your KYC process as part of an application to one of the jobs (we do this so you don't have to invest time without the prospect of a project)`,
    [TalentStatus.Applied]: `You can now browse all jobs and apply by yourself or get invited. We will finalise your KYC process as part of an application to one of the jobs (we do this so you don't have to invest time without the prospect of a project)`,
    [TalentStatus.AppliedToUpper]: `You can now browse all jobs and apply by yourself or get invited. We will finalise your KYC process as part of an application to one of the jobs (we do this so you don't have to invest time without the prospect of a project)`,
    [TalentStatus.Waitlist]:
      'Your profile is currently being reviewed by our Talent Team.<br />If you are a good fit for our community, you will be invited to the next interview rounds.',
    [TalentStatus.PersonalInterview]:
      'Please check your email to schedule a call with one of our talent specialists.',
    [TalentStatus.TechnicalInterview]:
      'Please check your email to schedule your technical interview.',
    [TalentStatus.Onboarding]:
      'Congratulations! You have made it past all of our vetting steps - all that`s left now is to sign the framework agreement for your account to be fully verified and active.',
    [TalentStatus.Challenge]:
      'Please check your email to access and solve the challenge.',
    [TalentStatus.Verification]:
      'The identity check was completed successfully - we will proceed with the full vetting process once there is fitting job. In the meanwhile you can browse and apply to jobs.',
  }

export const TALENT_BANNER_INFO_NO_ACCESS: Partial<
  Record<Exclude<TalentStatus, 'Verification'>, string> &
    Record<TalentStatus.Verification, Record<TalentKycStatus, string>>
> = {
  [TalentStatus.Waitlist]:
    'Your profile is currently being reviewed by our Talent Team.<br />If you are a good fit for our community, you will be invited to the next interview rounds.',
  [TalentStatus.PersonalInterview]:
    'Please check your email to schedule a call with one of our talent specialists.',
  [TalentStatus.TechnicalInterview]:
    'Please check your email to schedule your technical interview.',
  [TalentStatus.Onboarding]:
    'Congratulations! You have made it past all of our vetting steps - all that`s left now is to sign the framework agreement for your account to be fully verified and active.',
  [TalentStatus.Challenge]:
    'Please check your email to access and solve the challenge.',
  [TalentStatus.Verification]: {
    Declined:
      'Your identity verification failed. Feel free to get in touch if you believe this was in error and would like to try again.',
    NotStarted:
      'Please check your email and verify your identity via the provided link. With a verified ID your will be able to view and apply to jobs.',
    Abandoned:
      'Please check your email and verify your identity via the provided link. With a verified ID your will be able to view and apply to jobs.',
    Approved:
      'The identity check was completed successfully - we will proceed with the full vetting process once there is fitting job. In the meanwhile you can browse and apply to jobs.',
    Expired:
      'Your identify verification attempt has expired. Please start a new session using the button below or contact us if you had any issues.',
    Started:
      'Your identify verification is in progress. Please follow the instructions in the popup or link in order to complete the process.',
    Submitted:
      'Your identify verification is in progress. We are waiting for a conclusion from our partner and will let you know as soon as that happens.',
  },
}

export const TALENT_BANNER_INFO_ACCESS: Partial<Record<TalentStatus, string>> =
  {
    [TalentStatus.Invited]: `You can now browse all jobs and apply by yourself or get invited. We will finalise your KYC process as part of an application to one of the jobs (we do this so you don't have to invest time without the prospect of a project)`,
    [TalentStatus.Applied]: `You can now browse all jobs and apply by yourself or get invited. We will finalise your KYC process as part of an application to one of the jobs (we do this so you don't have to invest time without the prospect of a project)`,
    [TalentStatus.AppliedToUpper]: `You can now browse all jobs and apply by yourself or get invited. We will finalise your KYC process as part of an application to one of the jobs (we do this so you don't have to invest time without the prospect of a project)`,
    [TalentStatus.Waitlist]:
      'Your profile is currently being reviewed by our Talent Team.<br />If you are a good fit for our community, you will be invited to the next interview rounds.',
    [TalentStatus.PersonalInterview]:
      'Please check your email to schedule a call with one of our talent specialists.',
    [TalentStatus.TechnicalInterview]:
      'Please check your email to schedule your technical interview.',
    [TalentStatus.Onboarding]:
      'Congratulations! You have made it past all of our vetting steps - all that`s left now is to sign the framework agreement for your account to be fully verified and active.',
    [TalentStatus.Challenge]:
      'Please check your email to access and solve the challenge.',
    [TalentStatus.Verification]:
      'The identity check was completed successfully - we will proceed with the full vetting process once there is fitting job. In the meanwhile you can browse and apply to jobs.',
  }

export const VERIFICATION_STATUS_LABEL: Partial<
  Record<TalentKycStatus, string>
> = {
  Approved: 'verified',
  Abandoned: 'abandoned',
  Declined: 'failed',
  Expired: 'expired',
  NotStarted: 'pending',
  Started: 'in progress',
  Submitted: 'submitted',
}
